body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  user-select: none;
}

input, textarea {
  user-select: auto;
}
